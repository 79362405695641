import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  Grid,
  Input,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TrackOrderComponent from "./trackOrder.component";
import OrderSummaryComponent from "./orderSummary.component";
import CustomerDetailComponent from "./customerDetail.component";
import { useEffect, useState } from "react";
import { Label } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SellerDetailComponent from "./sellerDetail.component";
import { generalToastStyle } from "../../utils/toast.styles";
import { toast, ToastContainer } from "react-toastify";

const ProductCardSmall = ({ orderDetails }) => {
  return (
    <div
      style={{
        width: "100%",
        marginTop: "30px",
        height: "max-content",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "130px",
          height: "130px",
          borderRadius: "12px",
          border: "1px solid #e7e7e7",
          overflow: "hidden",
        }}
      >
        <img
          src={`https://api.sadashrijewelkart.com/assets/${orderDetails?.images[0]["file"]}`}
          alt=""
          style={{ width: "100%" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          height: "130px",
          width: "50%",
          display: "flex",
          flexDirection: "column",
          marginLeft: "30px",
        }}
      >
        <p style={{ margin: 0, fontSize: "1.4rem", fontWeight: 600 }}>
          {orderDetails?.product_name}
        </p>
        <p
          style={{
            margin: 0,
            marginTop: "auto",
            color: "gray",
            lineHeight: "2rem",
            fontWeight: 500,
            fontSize: "1.1rem",
          }}
        >
          Size: {orderDetails.height} {"     "} Quantity: 1
        </p>
        <p
          style={{
            margin: 0,
            color: "gray",
            fontWeight: 500,
            lineHeight: "2rem",
            fontSize: "1.1rem",
          }}
        >
          Price :{" "}
          <span style={{ color: "black" }}>
            {" "}
            Rs: {orderDetails?.amount_due}
          </span>
        </p>
        <p
          style={{
            margin: 0,
            color: "gray",
            fontWeight: 500,
            lineHeight: "2rem",
          }}
        >
          Deliver By: 2nd May, 2024
        </p>
      </div>
    </div>
  );
};
const OrderDetail = ({ id }) => {
  const admin = JSON.parse(localStorage.getItem("admin"));

  const [open, setOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [logistics, setLogistics] = useState("");
  const [cancleOrderSwitch, setCancleOrderSwitch] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      if (!admin.token) return;
      const { data } = await axios.get(
        `https://api.sadashrijewelkart.com/v1.0.0/seller/orders/all.php?type=order_details&order_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("order summary", data.response);
      setOrderDetails(data.response);
    })();
  }, []);

  const handleCancelOrder = async () => {
    if (!logistics) return;

    const formData = new FormData();
    formData.append("type", "admin_cancel");
    formData.append("shipment_id", id);
    formData.append("logistics", logistics);

    try {
      const { data } = await axios.post(
        `https://api.sadashrijewelkart.com/v1.0.0/admin/orders/all.php`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("data================>", data);
      if (data?.success === 0) {
        toast(data.message, generalToastStyle);
      } else {
        navigate("/orders");
      }
    } catch (err) {
      console.log(err?.response?.data?.message, "====================error");
      toast(err?.response?.data?.message, generalToastStyle);
    }
  };

  const handleFulfillOrder = async () => {
    if (cancleOrderSwitch) {
      await handleCancelOrder();
      return;
    }
    // if (!trackingNumber) return;
    if (!logistics) return;

    const formData = new FormData();
    formData.append("type", "create_customer_shipment");
    formData.append("order_record_id", id);
    formData.append("logistics", logistics);

    try {
      const { data } = await axios.post(
        `https://api.sadashrijewelkart.com/v1.0.0/admin/orders/all.php`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("data================>", data);
      if (data?.success === 0) {
        toast(data.message, generalToastStyle);
      } else {
        navigate("/orders");
      }
    } catch (err) {
      console.log(err?.response?.data?.message, "====================error");
      toast(err?.response?.data?.message, generalToastStyle);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "max-content",
        backgroundColor: "#e7e7e7",
      }}
    >
      <ToastContainer />
      <Dialog
        maxWidth={"lg"}
        style={{
          width: "100%",
          height: "max-content",
          margin: "auto",
        }}
        open={open}
        onClose={handleClose}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "30px",
            height: "max-content",
            width: "759px",
            borderRadius: "10px",
          }}
        >
          <p style={{ fontSize: "1.7rem", margin: 0, fontWeight: "bold" }}>
            {cancleOrderSwitch ? "Cancel" : "Fulfill"} Order
          </p>
          <div style={{ width: "100%", height: "300px", overflowY: "scroll" }}>
            {orderDetails.map((orderData) => (
              <ProductCardSmall orderDetails={orderData} />
            ))}
          </div>
          <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
            Tracking Information
          </p>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "47%" }}>
              <p style={{ color: "gray", fontWeight: 600 }}>Tracking Number</p>
              <TextField
                fullWidth
                onChange={(e) => setTrackingNumber(e.target.value)}
              ></TextField>
            </div>
            <div style={{ width: "47%" }}>
              <p style={{ color: "gray", fontWeight: 600 }}>Shipping Carrier</p>
              <TextField
                fullWidth
                onChange={(e) => setLogistics(e.target.value)}
              ></TextField>
            </div>
          </div>
          <Divider style={{ marginTop: "30px" }} />
          <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
            Notify customer of shipment
          </p>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Checkbox style={{}} />
            <p
              style={{
                width: "70%",
                color: "gray",
                fontSize: "1.2rem",
                fontWeight: 600,
              }}
            >
              Send shipment details to your customer now
            </p>
          </div>
          <div style={{ width: "100%", display: "flex" }}>
            <Button
              style={{
                marginLeft: "auto",
                width: "250px",
                height: "70px",
                color: "white",
                backgroundColor: "#A36E29",
                fontWeight: 600,
              }}
              onClick={() => handleFulfillOrder()}
            >
              FulFill Order
            </Button>
          </div>
        </div>
      </Dialog>
      <Grid
        spacing={6}
        container
        style={{
          paddingTop: "60px",
          paddingLeft: "100px",
          paddingRight: "100px",
          width: "100%",
          height: "max-content",
        }}
      >
        <Grid item xs={12} style={{ height: "100px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <span
              style={{
                width: "300px",
                height: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <ArrowBackIcon
                style={{ fontSize: "3rem", color: "#797979" }}
                onClick={() => navigate("/orders")}
              />
              <div>
                <p style={{ fontSize: "3rem", margin: 0, lineHeight: 1 }}>
                  SS75102
                </p>
                <p style={{ margin: 0, color: "gray" }}>02/10/2024 at 4:15pm</p>
              </div>
            </span>
            <div
              style={{
                width: "80px",
                height: "40px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                backgroundColor: "white",
                borderRadius: "5px",
                fontSize: "20px",
                border: "1px solid #b7b7b7",
              }}
            >
              <span style={{ color: "#0D9C00" }}>●</span>Paid
            </div>
            <Button
              variant="outlined"
              style={{
                marginLeft: "auto",
                width: "300px",
                height: "62px",
                backgroundColor: "white",
                borderColor: "#A36E29",
                color: "#A36E29",
              }}
              onClick={() => {
                setCancleOrderSwitch(true);
                setOpen(true);
              }}
            >
              Cancel Order
            </Button>
            <Button
              variant="contained"
              style={{
                marginLeft: "20px",
                width: "300px",
                height: "62px",
                backgroundColor: "#A36E29",
              }}
              onClick={() => {
                setCancleOrderSwitch(false);
                setOpen(true);
              }}
            >
              FulFill Order
            </Button>
          </div>
        </Grid>
        <Grid item xs={8}>
          <OrderSummaryComponent orderDetails={orderDetails} />
          <div style={{ padding: "40px", paddingTop: 0, paddingBottom: 0 }}>
            <p style={{ fontSize: "2rem", fontWeight: 600 }}> Order Status</p>
          </div>
          <TrackOrderComponent />
        </Grid>
        <Grid item xs={4}>
          <CustomerDetailComponent
            userData={orderDetails[0]?.user_address[0]}
          />
          <SellerDetailComponent userData={orderDetails[0]?.company_address} />
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderDetail;
