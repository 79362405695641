import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Divider,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  createTheme,
  Dialog,
  ThemeProvider,
  TextareaAutosize,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  SwipeableDrawer,
} from "@mui/material";
import "./product.styles.scss";
import { Close, Done, Refresh, Search } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import { generalToastStyle } from "../../utils/toast.styles";
import InputTextField from "../input-text-field/input-text-field.component";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a36e29",
    },
  },
  typography: {
    fontFamily: '"Work Sans", sans-serif',
  },
});

const ProductComponent = () => {
  const product = JSON.parse(localStorage.getItem("admin"));
  const [page, setPage] = useState(0);
  const [id, setId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchText, setSearchText] = useState(); // for search
  const [products, setProducts] = useState(null); /// stores all the products
  const [showDrawer, setShowDrawer] = useState(false);
  const [message, setMessage] = useState();
  const [productClicked, setProductClicked] = useState(0);
  const [dialog, setDialog] = useState(false);

  const getProduct = () => {
    console.log(product);
    axios
      .get("https://api.sadashrijewelkart.com/v1.0.0/admin/product/all.php", {
        headers: {
          Authorization: `Bearer ${product.token}`,
        },
      })
      .then((response) => {
        setProducts(response.data.response);
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  const handleBlockProductDialog = () => {
    const requestData = {
      action: "product-block",
      id: id,
      reason: message,
    };
    console.log(id);
    axios
      .put(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/update.php",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${product.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast(response.data.message, generalToastStyle);
        getProduct();
        setDialog(false);
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  const allowProduct = (productId) => {
    const requestData = {
      action: "product-allow",
      id: productId,
    };

    axios
      .put(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/update.php",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${product.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast(response.data.message, generalToastStyle);
        getProduct();
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error.response.data.message, generalToastStyle);
      });
  };

  const blockProduct = (productId) => {
    console.log(productId);
    setId(productId);
    setDialog(true);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rowClicked = (row) => {
    setProductClicked(products.indexOf(row));
    setShowDrawer(true);
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <div className="product-component">
      <ToastContainer />
      {products === null || products.length === 0 ? (
        <></>
      ) : (
        <ThemeProvider theme={theme}>
          <SwipeableDrawer
            anchor="right"
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
            PaperProps={{ style: { width: "60%" } }} // Adjust the width as needed
          >
            {products && products.length > 0 && productClicked !== null && (
              <div style={{ padding: "1vw", paddingLeft: "2vw" }}>
                {/* Image Gallery */}
                <div style={{ display: "flex", marginBottom: "20px" }}>
                  {products[productClicked].images &&
                    products[productClicked].images.map((image, index) => (
                      <div key={index} style={{ marginRight: "10px" }}>
                        {image.type === "img" ? (
                          <img
                            src={`https://api.sadashrijewelkart.com/assets/${image.file}`}
                            alt={`Product ${index + 1}`}
                            style={{ width: "150px" }}
                          />
                        ) : (
                          <video
                            controls
                            width="300"
                            height="150"
                            style={{ objectFit: "cover" }}
                          >
                            <source
                              src={`https://api.sadashrijewelkart.com/assets/${image.file}`}
                              type="video/mp4"
                            />
                          </video>
                        )}
                      </div>
                    ))}
                </div>
                {/* Product Details */}
                <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  {products[productClicked].name}
                </div>
                <div
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <div style={{ flex: "1", marginRight: "20px" }}>
                    <div style={{ marginTop: "10px", fontSize: "1.2rem" }}>
                      <strong>Description:</strong>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: products[productClicked].description,
                        }}
                      />
                      <div style={{ fontSize: "1.2rem", marginBottom: "8px" }}>
                        <strong>Category:</strong>{" "}
                        {products[productClicked].category}
                      </div>
                      <div style={{ fontSize: "1.2rem", marginBottom: "8px" }}>
                        <strong>Sub-Category:</strong>{" "}
                        {products[productClicked].sub_category}
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: "1" }}>
                    <div style={{ fontSize: "1.2rem", marginBottom: "8px" }}>
                      <strong>Weight:</strong> {products[productClicked].weight}
                    </div>
                    <div style={{ fontSize: "1.2rem", marginBottom: "8px" }}>
                      <strong>Purity:</strong> {products[productClicked].purity}
                    </div>
                    <div style={{ fontSize: "1.2rem", marginBottom: "8px" }}>
                      <strong>Height:</strong> {products[productClicked].height}
                    </div>
                    <div style={{ fontSize: "1.2rem", marginBottom: "8px" }}>
                      <strong>Width:</strong> {products[productClicked].width}
                    </div>
                    <div style={{ fontSize: "1.2rem", marginBottom: "8px" }}>
                      <strong>Price:</strong> {products[productClicked].price}
                    </div>
                  </div>
                </div>
                {/* Customization Section */}
                <div style={{ fontSize: "1.2rem", marginBottom: "20px" }}>
                  <div
                    style={{
                      marginBottom: "10px",
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    Customization Options:
                  </div>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Metal
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Diamond Type
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Size
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Radius
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Price
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Made On Order
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {products[productClicked]?.customizations?.variants &&
                          products[productClicked]?.customizations?.variants
                            .options &&
                          products[
                            productClicked
                          ]?.customizations?.variants.options.map(
                            (option, index) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                              >
                                <TableCell>
                                  {option.for_customization_options?.[0]}
                                </TableCell>
                                <TableCell>
                                  {option.for_customization_options?.[1]}
                                </TableCell>
                                <TableCell>
                                  {option.for_customization_options?.[2]}
                                </TableCell>
                                <TableCell>
                                  {option.for_customization_options?.[3]}
                                </TableCell>
                                <TableCell>{option.price}</TableCell>
                                <TableCell>
                                  {option.made_on_order === "1" ? "Yes" : "No"}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            )}
          </SwipeableDrawer>
        </ThemeProvider>
      )}
      <div className="head">
        <div className="head-txt">Products</div>
      </div>
      <div className="secondary-div">
        <div className="secondary-content">
          <p>
            <span className="selected-text">1 Product(s) </span>
          </p>
        </div>
        <div class="input-container">
          <Search class="icon" />
          <input
            class="input-field"
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
        </div>
      </div>
      <Divider />
      <ThemeProvider theme={theme}>
        <Paper className="table-paper">
          {products === null ? (
            <CircularProgress
              style={{
                margin: "auto",
                display: "flex",
                height: "100%",
              }}
            />
          ) : (
            <TableContainer style={{ maxHeight: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Variants</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row?.id}
                        >
                          <TableCell
                            className="name-content"
                            onClick={() => rowClicked(row)}
                          >
                            <div className="name-content-inner">
                              {row?.images ? (
                                <img
                                  className="company-img"
                                  alt="product"
                                  src={`https://api.sadashrijewelkart.com/assets/${row?.images[0]?.file}`}
                                />
                              ) : (
                                <img
                                  className="company-img"
                                  alt="product"
                                  src={``}
                                />
                              )}
                              {row?.name}
                            </div>
                          </TableCell>
                          <TableCell className="name-content">
                            <div className="name-content-inner">
                              <img
                                className="company-img"
                                alt="product"
                                src={`https://api.sadashrijewelkart.com/assets/${row?.company?.logo}`}
                              />

                              {row.company.name}
                            </div>
                          </TableCell>
                          <TableCell>
                            {row.customizations &&
                            row.customizations.variants &&
                            row.customizations.variants.count >= 1
                              ? `${row.customizations.variants.count} variants`
                              : "1 variant"}
                          </TableCell>
                          <TableCell>{row.price}</TableCell>
                          <TableCell className="actions-content">
                            {row.admin_verified === "0" ? (
                              <div>
                                <Done
                                  className="allow"
                                  onClick={() => allowProduct(row.id)}
                                />
                                <Close
                                  className="block"
                                  onClick={() => blockProduct(row.id)}
                                />
                              </div>
                            ) : row.admin_verified === "1" ? (
                              <Close
                                className="block"
                                onClick={() => blockProduct(row.id)}
                              />
                            ) : row.admin_verified === "2" ? (
                              <div className="rejected">Rejected</div>
                            ) : (
                              <div>
                                <Done
                                  className="allow"
                                  onClick={() => allowProduct(row.id)}
                                />
                                <Close
                                  className="block"
                                  onClick={() => blockProduct(row.id)}
                                />
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100, 200]}
                component="div"
                count={products.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Paper>
      </ThemeProvider>
      {/* Dialog to add a response for rejecting the product */}
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        disableEscapeKeyDown
        sx={{
          "& .MuiDialogTitle-root": {
            fontSize: "1.5rem",
            fontWeight: 600,
            marginBottom: "10px",
          },
          "& .MuiDialogContent-root": {
            fontSize: "1.2rem",
            fontWeight: 400,
            marginBottom: "10px",
          },
        }}
      >
        <ThemeProvider theme={theme}>
          <DialogTitle>Add Rejection Message</DialogTitle>
          <Divider />
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            Please Enter the rejection message for rejecting the product.
            <br />
            <TextareaAutosize
              className="field"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              minRows={3}
              placeholder={`Enter message here`}
              style={{
                padding: "5px",
                marginBottom: "10px",
                marginTop: "10px",
                fontSize: "16px",
              }}
            />
          </DialogContent>
          <DialogActions sx={{ marginBottom: "10px", marginRight: "10px" }}>
            <Button onClick={() => setDialog(false)}>Close</Button>
            <Button
              variant="contained"
              onClick={handleBlockProductDialog}
              className="closeButton"
            >
              Send
            </Button>
          </DialogActions>
        </ThemeProvider>
      </Dialog>
    </div>
  );
};

export default ProductComponent;
