import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import "./reviewsPage.styles.scss";
import CustomDrawer from "../../components/drawer/drawer.component";
import ReviewComponent from "../../components/reviews/review.component";
import axios from "axios";
import { Close, Done, Refresh, Search } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";

import { generalToastStyle } from "../../utils/toast.styles";

const ReviewsPage = () => {
  let { section } = useParams();
  const admin = JSON.parse(localStorage.getItem("admin"));
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    axios
      .get(
        "https://api.sadashrijewelkart.com/v1.0.0/admin/product/reviews.php?type=all&page=1&page_size=100",
        {
          headers: {
            Authorization: `Bearer ${admin.token}`,
          },
        }
      )
      .then((response) => {
        setReviews(response?.data?.response?.reviews);
        console.log(response?.data?.response);
      })
      .catch((error) => {
        console.log(error);
        toast.warn(error?.response?.data?.message, generalToastStyle);
      });
  }, []);

  return (
    <div className="product">
      <CustomDrawer section={"reviews"} />
      <Divider orientation="vertical" className="divider" />
      <div className="component">
        <ReviewComponent row={reviews} />
      </div>
    </div>
  );
};

export default ReviewsPage;
