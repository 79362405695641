import { React, useEffect, useState } from "react";
import { Button, Divider } from "@mui/material";

import {
  Dashboard,
  Business,
  List,
  Shop,
  Category,
  ListAlt,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import "./drawer.styles.scss";

import DrawerItem from "./item/item.component";

const CustomDrawer1 = ({ section }) => {
  const navigate = useNavigate();
  const admin = JSON.parse(localStorage.getItem("admin"));
  const imageUrl = admin["image"]
    ? `https://api.sadashrijewelkart.com/${admin["image"]}`
    : process.env.PUBLIC_URL + "/assets/logoNew.png";

  const isSubcategorySelected = section === "products" || section === "orders";
  const [showSubCategories, setShowSubCategories] = useState(
    isSubcategorySelected
  );

  const handleCategoriesClick = () => {
    setShowSubCategories(!showSubCategories);
    if (section !== "categories") {
      navigate("/categories");
    }
  };

  return (
    <div className="drawer-component">
      <div className="data">
        <img
          alt="logo"
          src={process.env.PUBLIC_URL + "/assets/logo_dark.png"}
          className="logo"
        />
        <Divider />
        <div className="drawer-content">
          <img alt="profile" className="profile" src={imageUrl} />
          <div className="name">{admin["name"]}</div>
          <div className="edit-option">Edit</div>
        </div>
        <DrawerItem
          title="Dashboard"
          value="dashboard"
          icon={<Dashboard />}
          section={section}
          clickAction={() => {
            if (section !== "dashboard") navigate("/home");
          }}
          isSelected={section === "dashboard"}
        />
        <DrawerItem
          title="Organizations"
          value="organizations"
          icon={<Business />}
          section={section}
          clickAction={() => {
            if (section !== "organizations") navigate("/organizations");
          }}
          isSelected={section === "organizations"}
        />
        <DrawerItem
          title="Shops"
          value="shops"
          icon={<Shop />}
          arrowicon={
            showSubCategories ? <ExpandLessIcon /> : <ChevronRightIcon />
          }
          section={section}
          clickAction={handleCategoriesClick}
          hassubcategory={true}
          isSelected={isSubcategorySelected}
        />
        {showSubCategories && (
          <div className="sub-categories">
            <DrawerItem
              title="Categories"
              value="categories"
              icon={<List />}
              section={section}
              clickAction={() => {
                if (section !== "categories") navigate("/categories");
              }}
              isaSubcategory={true}
              isSelected={section === "categories"}
            />
            <DrawerItem
              title="Products"
              value="products"
              icon={<Category />}
              section={section}
              clickAction={() => {
                if (section !== "products") navigate("/products");
              }}
              isaSubcategory={true}
              isSelected={section === "products"}
            />
            <DrawerItem
              title="Reviews"
              value="reviews"
              icon={<Category />}
              section={section}
              clickAction={() => {
                if (section !== "reviews") navigate("/reviews");
              }}
              isaSubcategory={true}
              isSelected={section === "reviews"}
            />
            <DrawerItem
              title="Orders"
              value="orders"
              icon={<Category />}
              section={section}
              clickAction={() => {
                if (section !== "orders") navigate("/orders");
              }}
              isaSubcategory={true}
              isSelected={section === "orders"}
            />
            <DrawerItem
              title="Coupons"
              value="coupons"
              icon={<Category />}
              section={section}
              clickAction={() => {
                if (section !== "coupons") navigate("/coupons");
              }}
              isaSubcategory={true}
              isSelected={section === "coupons"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const CustomDrawer = ({ section }) => {
  const navigate = useNavigate();
  const [logoUrl, setLogoUrl] = useState("");

  const admin = JSON.parse(localStorage.getItem("admin"));

  useEffect(() => {
    const storedLogoUrl = localStorage.getItem("logoUrl");

    // Check if the storedLogoUrl is not null or undefined
    if (storedLogoUrl) {
      setLogoUrl(JSON.parse(storedLogoUrl));
    }
  }, []);

  const isSubcategorySelected =
    section === "categories" ||
    section === "products" ||
    section === "reviews" ||
    section === "orders" ||
    section === "payments";
  const [showSubCategories, setShowSubCategories] = useState(
    isSubcategorySelected
  );

  const handleCategoriesClick = () => {
    setShowSubCategories(!showSubCategories);
    if (section !== "categories") {
      navigate("/categories");
    }
  };
  const handleProfileEdit = () => {
    navigate("/profile");
  };

  const imageUrl = logoUrl
    ? `https://api.sadashrijewelkart.com/assets/${logoUrl}`
    : process.env.PUBLIC_URL + "/assets/logoNew.png";

  return (
    <div className="drawer-component">
      <div
        className="data"
        style={{
          height: "100%",
          width: "335px",
          position: "fixed",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          alt="logo"
          src={process.env.PUBLIC_URL + "/assets/logo_dark.png"}
          className="logo"
        />
        <Divider />
        <div className="drawer-content">
          <img alt="profile" className="profile" src={imageUrl} />
          <div className="name">{admin["name"]}</div>
          {/* <div className="edit-option" onClick={handleProfileEdit}>
            Edit
            </div> */}
        </div>

        <DrawerItem
          title="Dashboard"
          value="dashboard"
          icon={<Dashboard />}
          section={section}
          clickAction={() => {
            if (section !== "dashboard") navigate("/dashboard");
          }}
        />
        <DrawerItem
          title="Organizations"
          value="organizations"
          icon={<Dashboard />}
          section={section}
          clickAction={() => {
            if (section !== "organizations") navigate("/organizations");
          }}
        />

        <DrawerItem
          title="Products"
          value="products"
          icon={<ListAlt />}
          section={section}
          clickAction={() => {
            if (section !== "products") navigate("/products");
          }}
        />

        <DrawerItem
          title="Orders"
          value="orders"
          icon={<Category />}
          section={section}
          clickAction={() => {
            if (section !== "orders") navigate("/orders");
          }}
        />
        <DrawerItem
          title="Payments"
          value="payments"
          icon={<Category />}
          section={section}
          clickAction={() => {
            if (section !== "payments") navigate("/payments");
          }}
        />
        <DrawerItem
          title="Categories"
          value="categories"
          icon={<List />}
          section={section}
          clickAction={() => {
            if (section !== "categories") navigate("/categories");
          }}
        />

        <DrawerItem
          title="Reviews"
          value="reviews"
          icon={<Category />}
          section={section}
          clickAction={() => {
            if (section !== "reviews") navigate("/reviews");
          }}
        />

        <DrawerItem
          title="Coupons"
          value="coupons"
          icon={<Category />}
          section={section}
          clickAction={() => {
            if (section !== "coupons") navigate("/coupons");
          }}
        />
        <div
          style={{
            marginTop: "auto",
            width: "95%",
            height: "70px",
            borderTop: "3px solid lightgray",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Button
            fullWidth
            style={{
              color: "gray",
              fontWeight: 700,
            }}
            onClick={() => {
              localStorage.removeItem("token");
              navigate("/");
            }}
          >
            <ExitToAppIcon style={{ fontSize: "1.5rem", color: "#a36e29" }} />
            <p style={{ width: "70%", textAlign: "left", paddingLeft: "20px" }}>
              Logout
            </p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomDrawer;

// export default CustomDrawer;
